import React, { useState } from "react";
import { ToastNotificationError } from "../../common";

const NewFileComponent = ({
  color,
  sectionId,
  icon,
  title,
  description,
  iconBg,
  cardClass,
  isSelected,
  action,
  handleEdit,
  handleDelete,
  hasBtns,
  type,
  accept,
  hasBash,
  isChecked,
  showEditBtn,
  handleChangeOptionName,
}) => {
  const ref = React.useRef();
  const handleFileUpload = () => {
    ref.current.click();
  };

  const [isEditMode, setIsEditMode] = useState(false);
  const [newName, setNewName] = useState(title);

  const handleEditMode = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsEditMode(!isEditMode);
  };

  const handleCancelEditMode = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsEditMode(false);
    setNewName(title);
  };

  const handleSaveName = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (newName === title) {
      setIsEditMode(false);
      return;
    }
    if (newName.trim() === "") {
      setNewName(title);
      ToastNotificationError(1, "Name cannot be empty");
      setIsEditMode(false);
      return;
    }
    setIsEditMode(false);
    handleChangeOptionName(sectionId, newName);
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  return (
    <div
      onClick={type === "file" ? handleFileUpload : action}
      className={`position-relative new-document-card border rounded-lg p-3 ${
        cardClass ?? ""
      } ${isSelected ? "template-card-selected" : ""}`}
    >
      {showEditBtn &&
        (isEditMode ? (
          <div
            className="position-absolute d-flex justify-content-end"
            style={{
              zIndex: "100",
              right: "0",
              paddingRight: "16px",
              bottom: "55px",
              gap: "5px",
            }}
          >
            <button
              className="btn btn-sm btn-secondary rounded-circle btn-success"
              style={{
                width: "30px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={(e) => handleSaveName(e)}
            >
              <i className="fas fa-check"></i>
            </button>
            <button
              className="btn btn-sm btn-danger rounded-circle btn-success"
              style={{
                width: "30px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={(e) => handleCancelEditMode(e)}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        ) : (
          <button
            className="btn btn-sm position-absolute btn-secondary"
            style={{
              zIndex: "100",
              right: "22px",
              bottom: "55px",
            }}
            onClick={(e) => handleEditMode(e)}
          >
            <i className="fas fa-pen"></i>
          </button>
        ))}

      {isChecked && (
        <div
          className="position-absolute bg-primary text-white p-2 d-flex justify-content-center align-items-center"
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            top: "5px",
            right: "5px",
            zIndex: "150",
            fontSize: "0.7rem",
          }}
        >
          <i className="fas fa-check"></i>
        </div>
      )}
      {type === "file" && (
        <input
          ref={ref}
          type="file"
          accept={accept ?? "*"}
          className="d-none"
          onChange={(e) => {
            action(e);
          }}
        />
      )}
      {hasBash && (
        <div
          className="position-absolute bg-primary text-white p-1 rounded-lg"
          style={{
            top: "5px",
            left: "5px",
            zIndex: "150",
            fontSize: "0.7rem",
          }}
        >
          Plnify
        </div>
      )}
      {hasBtns && (
        <div className="template-card-btn-container position-absolute">
          <button
            className="btn btn-secondary btn-sm border-0"
            title="Edit"
            onClick={(e) => {
              e.stopPropagation();
              handleEdit();
            }}
          >
            <i class="fa fa-solid fa-pen"></i>{" "}
          </button>
          <button
            title="Delete"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
            className="btn btn-danger btn-sm border-0"
          >
            <i className="fas fa-times"></i>
          </button>
        </div>
      )}
      <div
        className={`text-black-50 card w-100 ${
          iconBg ?? "bg-light"
        }  border-0 p-0 m-0 d-flex justify-content-center align-items-center`}
        style={{
          width: "100%",
          height: "120px",
        }}
      >
        <div className="d-flex justify-content-center align-items-center">
          <i
            class={` fas fa-solid fa-3x ${icon}`}
            style={{
              color: color,
              transition: "all 0.3s",
            }}
          ></i>
        </div>
      </div>
      <div className="text-black-50">
        <span
          className="font-weight-bold line-clamp-1 my-1"
          style={{
            color: color,
            fontSize: "0.9rem",
            lineHeight: "1",
          }}
          title={title}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {isEditMode ? (
            <input
              type="text"
              className="w-100 border-0"
              autoFocus
              value={newName}
              onChange={(e) => handleNameChange(e)}
            />
          ) : (
            title
          )}
        </span>
        <p
          style={{
            fontSize: "0.8rem",
            lineHeight: "1",
          }}
        >
          {description}
        </p>
      </div>
    </div>
  );
};

export default NewFileComponent;
