/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import db from "./common/db";
import Meeting from "./components/meet/Meeting";
import { ROUTES } from "./constant";
import MensajesIngles from "./language/en-Us.json";
import MensajesEspanol from "./language/es-Es.json";
import ChangePasswordContainer from "./page/authentication/changePassword/ChangePasswordContainer";
import ConfirmEmailContainer from "./page/authentication/confirmEmail/ConfirmEmailContainer";
import Join from "./page/authentication/join/Join";
import LoginContainer from "./page/authentication/login/loginContainer";
import PricacyPolicyContainer from "./page/authentication/privacyPolicy/privacyPolicyContainer";
import ResetPasswordContainer from "./page/authentication/resetPassword/resetPasswordContainer";
import SignUpPage from "./page/authentication/signUp/signUpPage";
import TermsAndConditionContainer from "./page/authentication/termsAndCondition/termsAndConditionContainer";
import CalenderComponent from "./page/calendar/calendar/calendarComponent";
import CollabInviteContainer from "./page/collab/collabInvite/CollabInviteContainer";
import CollabContainer from "./page/collab/collabPage/CollabContainer";
import { CollabViewContainer } from "./page/collab/collabView/CollabViewContainer";
import DashboardComponent from "./page/dashboard/dashboardComponent";
import CleanUpPage from "./page/errors/CleanPage";
import ErrorPageComponent from "./page/errors/ErrorPageComponent";
import { DirectionPage } from "./page/event/directions/DirectionPage";
import EventDetailContainer from "./page/event/eventDetail/EventDetailContainer";
import { DocsComponent } from "./page/files/editors/DocsComponent";
import { PDFComponent } from "./page/files/editors/PDFComponent";
import { SpreadSheets } from "./page/files/editors/SpreadSheets";
import Files from "./page/files/index";
import CreateOrganizationContainer from "./page/organization/createOrganization/CreateOrganizationContainer";
import SelectOrganizationContainer from "./page/organization/selectOrganization/SelectOrganizationContainer";
import UsersOrganizationContainer from "./page/organization/users/UsersContainers";
import ProfileContainer from "./page/profile/ProfileContainer";
import GuestListContainer from "./page/ticketsAndPasses/guestList/GuestListContainer";
import TodoContainer from "./page/todoPage/todo/TodoContainer";
import { TodoView } from "./page/todoPage/todo/TodoView";
import WalkthroughContainer from "./page/walkthrough/walkthroughComponent";
import WelcomeContainer from "./page/welcome/WelcomeContainer";
import { getAccessToken, getBasicInfo } from "./utils";
import RegisterPage from "./page/register/RegisterPage";

const myErrorHandler = async (error, info) => {
  console.log("OH NO ERROR HAPPEND", error, info);
  console.error(error, info.componentStack);
  /*const request = await api.post(
    `/General/SendEmail/?EmailAddress=support@plnify.com&title=Error customerbackend`,
    error.toString() + info.componentStack
  );
 */

  // Do something with the error
  // E.g. log to an error logging client here
};
const lenguageData = [];
const dataStateLenguage = (item) => {
  lenguageData[0] = item;
};

const Lenguage = ({ children }) => {
  const [DataSubmit, setDataSubmit] = useState({});

  const loadData = async () => {
    const appSettings = await db.appSettings.toArray();
    setDataSubmit({ locale: "en-Us", messages: MensajesIngles }); //Default
    if (appSettings && appSettings[0]) {
      if (appSettings[0].lenguage === 1) {
        setDataSubmit({ locale: "en-Us", messages: MensajesIngles });
      }
      if (appSettings[0].lenguage === 2) {
        setDataSubmit({ locale: "es-Es", messages: MensajesEspanol });
      }
    } else {
      await db.table("appSettings").put({ AccountGUID: 1 });
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <IntlProvider
      locale={DataSubmit ? DataSubmit.locale : "en-Us"}
      messages={DataSubmit?.messages}
      onError={(err) => {
        if (err.code === "MISSING_TRANSLATION") {
          console.warn("Missing translation", err.message);
          return;
        }
        // throw err;
      }}
    >
      {children}
    </IntlProvider>
  );
};

const App = (props) => {
  const accessToken = getAccessToken();
  return (
    <Lenguage>
      <ErrorBoundary
        FallbackComponent={ErrorPageComponent}
        onError={myErrorHandler}
      >
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path={ROUTES.LOGIN}
              component={() =>
                !accessToken ? (
                  <LoginContainer dataStateLenguage={dataStateLenguage} />
                ) : (
                  <Redirect to={ROUTES.CALENDAR} />
                )
              }
            />

            <Route exact path={ROUTES.JOIN} component={() => <Join />} />
            <Route
              exact
              path={ROUTES.SIGNUP}
              component={() => <SignUpPage />}
            />
            <Route
              exact
              path={ROUTES.FILES}
              component={(props) =>
                accessToken ? (
                  <Files {...props} />
                ) : (
                  <Redirect to={ROUTES.LOGIN} />
                )
              }
            />
            <Route
              exact
              path={ROUTES.DOCS}
              component={(props) =>
                accessToken ? (
                  <DocsComponent {...props} />
                ) : (
                  <Redirect to={ROUTES.LOGIN} />
                )
              }
            />
            <Route
              exact
              path={`${ROUTES.PDF}/:pdfFileId`}
              component={(props) =>
                accessToken ? (
                  <PDFComponent {...props} />
                ) : (
                  <Redirect to={ROUTES.LOGIN} />
                )
              }
            />
            <Route
              exact
              path={ROUTES.SHEETS}
              component={(props) =>
                accessToken ? (
                  <SpreadSheets {...props} />
                ) : (
                  <Redirect to={ROUTES.LOGIN} />
                )
              }
            />
            <Route
              exact
              path={ROUTES.TERMS_AND_CONDITION}
              component={(props) => <TermsAndConditionContainer {...props} />}
            />
            <Route
              exact
              path={ROUTES.SECURITY_AND_PRIVACY}
              component={(props) => <PricacyPolicyContainer {...props} />}
            />
            <Route
              exact
              path={ROUTES.RESET_PASSWORD}
              component={() => <ResetPasswordContainer />}
            />
            <Route
              exact
              path={ROUTES.CALENDAR}
              component={() =>
                accessToken ? (
                  <CalenderComponent />
                ) : (
                  <Redirect to={ROUTES.LOGIN} />
                )
              }
            />
            <Route
              exact
              path={ROUTES.CREATE_ORGANIZATION}
              component={() => (
                <CreateOrganizationContainer isBackButton={false} />
              )}
            />
            <Route
              exact
              path={ROUTES.SELECT_ORGANIZATION}
              component={(props) =>
                accessToken ? (
                  <SelectOrganizationContainer {...props} />
                ) : (
                  <Redirect to={ROUTES.LOGIN} />
                )
              }
            />
            <Route
              exact
              path={ROUTES.DASHBOARD}
              component={(props) =>
                accessToken ? (
                  <DashboardComponent {...props} />
                ) : (
                  <Redirect to={ROUTES.LOGIN} />
                )
              }
            />
            <Route
              exact
              path={ROUTES.CHANGE_PASSWORD}
              component={(props) => <ChangePasswordContainer {...props} />}
            />
            <Route
              exact
              path={ROUTES.ONBOARDING}
              component={(props) =>
                accessToken ? (
                  <WalkthroughContainer {...props} />
                ) : (
                  <Redirect to={ROUTES.LOGIN} />
                )
              }
            />
            <Route
              exact
              path={ROUTES.EVENT}
              component={(props) =>
                accessToken ? (
                  <EventDetailContainer {...props} />
                ) : (
                  <Redirect to={ROUTES.LOGIN} />
                )
              }
            />
            <Route
              exact
              path={ROUTES.USERS_ORGANIZATION}
              component={(props) =>
                accessToken ? (
                  <UsersOrganizationContainer {...props} />
                ) : (
                  <Redirect to={ROUTES.LOGIN} />
                )
              }
            />
            <Route
              exact
              path={ROUTES.TICKETS_AND_PASSES}
              component={(props) =>
                accessToken ? (
                  <GuestListContainer {...props} />
                ) : (
                  <Redirect to={ROUTES.LOGIN} />
                )
              }
            />
            <Route
              exact
              path={ROUTES.TODO}
              component={(props) =>
                getAccessToken() ? (
                  <TodoContainer {...props} />
                ) : (
                  <Redirect to={ROUTES.LOGIN} />
                )
              }
            />
            <Route
              path={ROUTES.TASKVIEW}
              component={(props) => <CollabViewContainer {...props} />}
            />
            <Route
              exact
              path={ROUTES.TODOVIEW}
              component={(props) =>
                getAccessToken() ? (
                  <TodoView {...props} />
                ) : (
                  <Redirect to={ROUTES.LOGIN} />
                )
              }
            />
            <Route
              exact
              path={ROUTES.CONFIRMED_EMAIL}
              component={(props) => <ConfirmEmailContainer {...props} />}
            />
            <Route
              exact
              path={ROUTES.COLLAB}
              component={(props) =>
                getAccessToken() ? (
                  <CollabContainer {...props} />
                ) : (
                  <Redirect to={ROUTES.LOGIN} />
                )
              }
            />
            <Route
              exact
              path={ROUTES.DIRECTION}
              component={(props) =>
                getAccessToken() ? (
                  <DirectionPage {...props} />
                ) : (
                  <Redirect to={ROUTES.LOGIN} />
                )
              }
            />
            <Route
              exact
              path={ROUTES.COLLAB_TYPE}
              component={(props) => <CollabContainer {...props} />}
            />
            <Route
              exact
              path={ROUTES.COLLAB_INVITE}
              component={(props) => <CollabInviteContainer {...props} />}
            />
            <Route
              exact
              path={ROUTES.SETTINGS}
              component={(props) => <ProfileContainer {...props} />}
            />
            <Route
              exact
              path={ROUTES.MEET}
              component={(props) => <Meeting {...props} />}
            />
            <Route
              exact
              path={ROUTES.WELCOME_SUCCESS}
              component={(props) => <WelcomeContainer {...props} />}
            />
            <Route
              exact
              path={ROUTES.CLEAN_UP}
              component={(props) => <CleanUpPage {...props} />}
            />
            <Route
              exact
              path={ROUTES.REGISTER}
              component={(props) => <RegisterPage {...props} />}
            />
            <Route
              path="/*"
              component={() => {
                throw new Error("404 Page not found");
              }}
            />
          </Switch>
          <ToastContainer transition={Slide} />
        </BrowserRouter>
      </ErrorBoundary>
    </Lenguage>
  );
};

export default App;
